import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/en/Layout"
import HomeSectionInfinite from "../../components/en/HomeSectionInfinite"
import Breadcrumbs from "../../components/en/Breadcrumbs"
import SEO from "../../components/en/SEO"

const TopicTemplate = ({ data }) => {
  const topic = data.topicsEn
  const cards = data.allCardsEn.edges.sort(
    (a, b) => -a.node.createdAt.localeCompare(b.node.createdAt)
  )

  const metaDescription =
    topic.description ||
    `Access the best content about spirituality, history and islam wisdom
    with our topics`

  const renderDescription = () => {
    if (topic.description) {
      return <p>{topic.description}</p>
    }

    return (
      <p>
        Access the best content about spirituality, history and islam wisdom
        with our topics
      </p>
    )
  }

  return (
    <Layout>
      <SEO
        title={topic.name}
        description={metaDescription}
        card={false}
        image={topic.imageUrl}
        canonical={`${process.env.GATSBY_HOST_URL}/en/topics/${topic.slug}`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage={topic.name}
            secondaryLink={"/en/topics"}
            secondaryLinkName="Topics"
            style={{ marginTop: 0, marginBottom: 30, background: topic.color }}
          />

          <div className="topics-header">
            <h1 style={{ color: topic.color }}>{topic.name}</h1>
            {renderDescription()}
          </div>

          <HomeSectionInfinite
            name=""
            cards={cards}
            style={{ marginTop: 50, marginBottom: 50 }}
            customClass="home-section-card-list"
          />
        </div>
      </div>
    </Layout>
  )
}

export default TopicTemplate

export const query = graphql`
  query($id: String!, $slug: String!) {
    topicsEn(id: { eq: $id }) {
      name
      color
      slug
      description
      createdAt
    }

    allCardsEn(filter: { topic: { slug: { eq: $slug } } }) {
      edges {
        node {
          name
          slug
          imageUrl
          type
          createdAt
          topic {
            name
            color
          }
          tags {
            name
          }
          meta {
            content
            author
          }
        }
      }
    }
  }
`
